import React, { useEffect, useState } from 'react';
import SwiperCore, { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Icon from '../../components/Icon/Icon';
import ThumbnailsPatternAndIcon from '../../components/ThumbnailsPatternAndIcon/ThumbnailsPatternAndIcon';
import TitleUnderlineParagraph from '../../components/TitleUnderlineParagraph/TitleUnderlineParagraph';
import Arrow from '../../images/Shape.svg';
import { cn } from '../../utils';
import * as styles from './AdvantagesSlider.module.scss';
import IAdvantagesSlider from './AdvantagesSlider.type';

const AdvantagesSlider = ({ slides }: IAdvantagesSlider) => {
    const [swiper, setSwiper] = useState<SwiperCore>();
    const [slidesOpacity, setSliderOpacity] = useState<"0.2s" | "0">("0");
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (swiper)
            // this slider breaks layout sometimes when resizing window (or swapping from horizontal to portrait view on mobile/tablet). The code below fixes it
            window.addEventListener(
                "resize",
                () => {
                    timeoutId && clearTimeout(timeoutId);
                    setTimeoutId(setTimeout(() => swiper.update(), 200)); // debouncing to prevent multiple updates - just once after finished resizing.
                },
                { passive: true }
            );
    }, [swiper]);

    return (
        <section className={styles.section}>
            <div className={cn("container", styles.container)}>
                <div className={styles.navigation}>
                    <button
                        disabled={activeSlide === 0}
                        onClick={() => swiper?.slideTo(activeSlide - 1)}
                        aria-label="Previous slide"
                        className={styles.prevArrow}
                    >
                        <Icon
                            localFile={{ publicURL: Arrow }}
                            altText="Arrow"
                        />
                    </button>
                    <ul className={styles.slideLinks}>
                        {slides.map((slide, slideIndex) => (
                            <li key={slide.label} className={styles.slideLink}>
                                <button
                                    onClick={() => swiper?.slideTo(slideIndex)}
                                    className={cn(
                                        slideIndex === activeSlide &&
                                            styles.active
                                    )}
                                >
                                    {slide.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className={styles.pagination}>
                        <span>{activeSlide + 1}</span>
                        {` / ${slides.length}`}
                    </div>
                    <button
                        disabled={activeSlide === slides.length - 1}
                        onClick={() => swiper?.slideTo(activeSlide + 1)}
                        aria-label="Next slide"
                        className={styles.nextArrow}
                    >
                        <Icon
                            localFile={{ publicURL: Arrow }}
                            altText="Arrow"
                        />
                    </button>
                </div>
                <Swiper
                    className={styles.slider}
                    slidesPerView={1}
                    modules={[EffectFade]}
                    effect="fade"
                    followFinger={false}
                    simulateTouch={false}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    onSlideChange={(slide) => setActiveSlide(slide.activeIndex)}
                    onInit={(swiper) => {
                        setSwiper(swiper);
                        setSliderOpacity("0.2s");
                    }}
                >
                    {slides.map((slide) => (
                        <SwiperSlide
                            key={slide.label}
                            className={styles.slide}
                            style={{ transition: slidesOpacity }}
                        >
                            <ThumbnailsPatternAndIcon
                                icon={slide.icon}
                                upperImage={slide.upperImage}
                                lowerImage={slide.lowerImage}
                                className={styles.thumbnails}
                            />
                            <TitleUnderlineParagraph
                                heading={slide.label}
                                headingLevel="h2"
                                text={slide.text}
                                underline="left"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default AdvantagesSlider;
