// extracted by mini-css-extract-plugin
export var active = "AdvantagesSlider-module--active--59a79";
export var container = "AdvantagesSlider-module--container--b52a0";
export var navigation = "AdvantagesSlider-module--navigation--81247";
export var nextArrow = "AdvantagesSlider-module--next-arrow--97573";
export var pagination = "AdvantagesSlider-module--pagination--9010c";
export var prevArrow = "AdvantagesSlider-module--prev-arrow--0af02";
export var section = "AdvantagesSlider-module--section--3edd5";
export var slide = "AdvantagesSlider-module--slide--cc5cb";
export var slideLink = "AdvantagesSlider-module--slide-link--05ea3";
export var slideLinks = "AdvantagesSlider-module--slide-links--bb7d9";
export var slider = "AdvantagesSlider-module--slider--8c2eb";
export var thumbnails = "AdvantagesSlider-module--thumbnails--3e45a";