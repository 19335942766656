import React from 'react';

import Pattern from '../../images/pattern.png';
import { cn } from '../../utils';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import * as styles from './ThumbnailsPatternAndIcon.module.scss';
import IThumbnailsPatternAndIcon from './ThumbnailsPatternAndIcon.type';

const ThumbnailsPatternAndIcon = (props: IThumbnailsPatternAndIcon) => {
    return (
        <div className={cn(styles.element, props.className)}>
            <Image className={styles.upperImage} {...props.upperImage} />
            <div
                style={{ backgroundImage: `url(${Pattern})` }}
                className={styles.pattern}
            />
            <div className={styles.iconWrapper}>
                <Icon className={styles.icon} {...props.icon} width={22} height={23}/>
            </div>
            <Image className={styles.lowerImage} {...props.lowerImage} />
        </div>
    );
};

export default ThumbnailsPatternAndIcon;
